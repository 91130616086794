import React from "react";
import "./News.css";
import data from "../../Utils/News.json";
import NewsCard from "../NewsCard/NewsCard";
import "swiper/css";
import { Swiper, SwiperSlide } from "swiper/react";
import { sliderSettings } from "../../Utils/common";

const News = () => {
  return (
    <section className="newsWrapper">
      <div className="divider"></div>
      <div className="flexCenter newsContainer">
        <div className="flexCenter newsTitle">
          <h1 className="boldText">News & Media</h1>
        </div>
        <div className="flexCenter newsCardElement">
          <Swiper {...sliderSettings}>
            {data?.map((e, i) => (
              <SwiperSlide>
                <NewsCard card={e} key={i} />
              </SwiperSlide>
            ))}
          </Swiper>

          {/* {data?.map((e, i) => (
            <NewsCard card={e} key={i}/>
          ))} */}
        </div>
      </div>
    </section>
  );
};

export default News;
