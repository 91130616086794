import React from 'react'
import './Certificates.css'
import data from '../../Utils/Certificates.json'

const Certificates = () => {
  return (
    <div className="certificatesWrapper">
      <div className="flexCenter certificatesContainer">
        <div className="flexColStart certificatesTitle">
          <h1 className="boldText">
            Certificates & Rewards
          </h1>
          <div className="hl"></div>
        </div>
        <div className="flexCenter certificatesDetail">
          {data?.map((e, i)=>(
            <div className="flexCenter certificatesInfo" key={i}>
              <div className="flexCenter certImage">
                <img src={e.certificate} alt="Certificate" />
              </div>
              <div className="flexStart certDes">
                {e.description}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}

export default Certificates