import React from "react";
import "./ContactUs.css";

const ContactUs = () => {
  const handleOpenEmailClient = () => {
    const email = "webadmin@worldbridge.com.kh";
    const subject = `Information Enquery from Website`;
    const body = "Enter your requirement here";

    const mailtoLink = `mailto:${email}?subject=${encodeURIComponent(
      subject
    )}&body=${encodeURIComponent(body)}`;

    // Open the user's default email client
    window.location.href = mailtoLink;
  };

  return (
    <section className="contactusWrapper">
      <div className="flexCenter paddings contactusContainer">
        <div className="flexCenter">
          <div className="flexColCenter contactusInfor">
            <h1 className="boldText"> For any information please contact us</h1>
            <div className="flexColCenter contactusElements">
              <span className="boldText">Email: </span>
              <span>media-mkt@worldbridge.com.kh</span>
            </div>
            <div className="flexColCenter contactusElements">
              <span className="boldText">Phone: </span>
              <span>023 424 682</span>
            </div>
            <div className="flexColCenter contactusElements">
              <span className="boldText"> Address: </span>
              <span>
                The Bridge SoHO Tower #38-25, National Assembly Street, <br />{" "}
                Tonle Bassc Commune, Chamkarmon District, Phnom Penh, Cambodia
              </span>
            </div>
            <button className="contactusButton" onClick={handleOpenEmailClient}>
              Contact Us Now
            </button>
          </div>
          <div className="flexCenter contactusMap">
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d3908.995861305557!2d104.935981!3d11.552154!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x310951a95c1ace7b%3A0xe89a08d8bf44b20f!2sWorldBridge%20Group%20Of%20Companies!5e0!3m2!1sen!2sus!4v1712559996597!5m2!1sen!2sus"
              width="600"
              height="450"
              title="google map"
              style={{ border: 0 }}
              allowfullscreen=""
              loading="lazy"
              referrerpolicy="no-referrer-when-downgrade"
            ></iframe>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ContactUs;
