import React from 'react'
import './Companies.css'
import Category from '../../Components/Category/Category'

const Companies = () => {
  return (
    <div className="companiesWrapper">
      <div className="companiesCover">
        <img src="./assets/images/elements/ourCompanies.jpg" alt="our companies" />
      </div>
      <div className="flexColStart companiesContainer">
        <div className="flexColStart companiesTitle">
          <h1 className='boldText'>Categories</h1>
          <div className="hl"></div>
        </div>
        <div className="flexCenter companiesCategories">
          <Category />
        </div>
      </div>
    </div>
  )
}

export default Companies