import React from "react";
import "./Businesses.css";
import data from "../../Utils/Business.json";

const Businesses = () => {
  return (
    <section className="flexCenter businessWrapper">
      <div className="flexCenter businessContainer">
        <h1 className="boldText">Our Businesses</h1>
        <div className="flexCenter businessEachelement">
          {data.map((e, i) => (
            <div className="flexCenter businessImage" key={i}>
              <img src={e.img} alt={e.name} width={60} />
              <h2>{e.name}</h2>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Businesses;
