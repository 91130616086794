import React from "react";
import "./Careers.css";
import data from "../../Utils/Careers.json";

const Careers = () => {
  const handleOpenEmailClient = () => {
    const email = "webadmin@worldbridge.com.kh";
    const subject = `Apply for Career at WBG`;
    const body = "Enter your information here";

    const mailtoLink = `mailto:${email}?subject=${encodeURIComponent(
      subject
    )}&body=${encodeURIComponent(body)}`;

    // Open the user's default email client
    window.location.href = mailtoLink;
  };
  return (
    <section className="careersWrapper">
      <div className="careerCover">
        <img src="../assets/images/elements/careers.jpg" alt="Careers" />
      </div>
      <div className="flexColCenter paddings careersContainer">
        <div className="flexCenter careerInfo">
          <div className="flexColCenter careerWhyUs">
            <h1 className="boldText">Foster a Better Careers with Us</h1>
            <p>
              At WorldBridge Group of Companies, you will be part of an
              energetic with incredible opportunities for inspiration,
              development, and innovation. The development of every staff member
              is very important to us that we are committed to helping you grow
              for your professional and personal development. Let us be your
              next career move! Your future colleagues are working on great
              projects every day. You can do so as well.
            </p>
          </div>
          <div className="flexCenter careerCulture">
            <h1 className="boldText">Our Culture</h1>
            <p>
              Learning never ends! We always view challenges as an opportunity
              to build a betterment. The success of our business is the success
              of our people as we make sure to create an environment where all
              our people participate and perform at their best. At WorldBridge
              Group of Companies, we have created a workplace culture with a
              “family feeling” that empowers our people to work together more
              effectively. We understand the value of solidarity and teamwork,
              building strong working relationships, and camaraderie are an
              asset to the workplace.
            </p>
          </div>
        </div>
        <div className="flexCenter careersAvail">
          <div className="flexColStart careerTitle">
            <h1 className="boldText">Careers Opportunities</h1>
            <div className="hl"></div>
            <span>WorldBridge Group offers the best internships and jobs.</span>
          </div>
          <div className="flexColStart careersDes">
            <p>
              Applicants are welcome to apply to as many vacancies as the desire
              to discuss ongoing requirements further with a member of our team
              by using the contact below.
            </p>
            <button onClick={handleOpenEmailClient}>Apply Now</button>
          </div>
          <div className="flexCenter careersData">
            {console.log(data.length)}
            {data.length > 0 ? (
              data.map((e) => (
                <div className="flexCenter" key={e.title}>
                  {e.title}
                </div>
              ))
            ) : (
              <div className="flexStart careersNoJob"> No Job available</div>
            )}
          </div>
        </div>
      </div>
    </section>
  );
};

export default Careers;
