import React from 'react'
import './NewsCard.css'
import { truncate } from 'lodash'
import {useNavigate} from 'react-router-dom'

const NewsCard = ({card}) => {
  const navigate = useNavigate();

  return (
    <div className="flexColStart newsCardContainer" onClick={()=>navigate(`../news/${card?.titleId}`)}>
      <img src={card?.thumbnail} alt="Thumbnail" />
      <h1 className="boldText">{truncate(card?.title, {length:"50"})}</h1>
      <p className='newsCardDes'>{truncate(card?.des, {length:"150"})}</p>
      <p className='newsCardCreateDate'>Created at {card?.date}</p>
    </div>
  )
}

export default NewsCard