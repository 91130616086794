import React from "react";
import "./NewsDetail.css";
import { useLocation } from "react-router-dom";
import { FaRegCalendarAlt } from "react-icons/fa";
import data from "../../Utils/News.json";

const NewsDetail = () => {
  const { pathname } = useLocation();
  const id = pathname.split("/").slice(-1)[0];
  const newsDetail = data.filter((e) => e.titleId === id);

  const des = newsDetail[0].des;
  const images = newsDetail[0].images

  return (
    <section className="flexCenter newsDetailWrapper">
      <div className="paddings flexCenter newsDetailContainer">
        <div className="newsDetailImage">
          <img src={newsDetail[0]?.thumbnail} alt="" />
        </div>
        <div className="flexStart newsDetailDate">
          <FaRegCalendarAlt /> &nbsp; {newsDetail[0].date}
        </div>
        <div className="flexColStart newsDetailInfo">
          <h1 className="boldText">{newsDetail[0]?.title}</h1>
          <div className="hl"></div>
          <div className="newsDetailDes">
            <div className="flexColStart newsDetailPara">
              {des.map((e) => (
                <p key={(e)}>{e}</p>
              ))}
            </div>
          </div>
          <div className="flexStart newsImages">
            {images.length > 0 ? images.map(image=>(
              <div className="newsImageList" key={image}> <img src={`../assets/images/news/${image}`} alt="News Photos"/></div>
            )) : <div></div>}
          </div>
        </div>
      </div>
    </section>
  );
};

export default NewsDetail;
