import React from 'react'
import './NewsPage.css'
import data from "../../Utils/News.json";
import NewsCard from '../../Components/NewsCard/NewsCard';

const NewsPage = () => {
  return (
    <div className="newsPageWrapper">
      <div className="flexCenter newsPageContainer">
        <div className="flexColStart newsPageTitle">
          <h1 className="boldText">
            News
          </h1>
          <div className="hl"></div>
        </div>
          <div className="flexCenter newsPagesInfo">
            {data?.map((e, i) => (
            <NewsCard card={e} key={i}/>
          ))}
          </div>
      </div>
    </div>
  )
}

export default NewsPage